@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
#container {
 
  h1 {

  text-align: center;
}
}
.padder{
  display:flex;
  height:auto;
  width:100%;
  padding:10rem;
}
.texter{
  text-align: center;
  h1{
    font-size:1.7rem
  }
}
.parent {
  width: 100%;
}

.distanci{
  display:flex;
  margin-top:2em;
  
}
.canvas-container{
  display:flex;
 
}
.etaj {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	text-align: center;
  justify-content: center;
  padding:5px;
  a { color: rgba(0, 0, 0, 0.755); text-decoration: none; }
 
}
.arrow {
  text-align: center;
  
  
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.h-container{
 h1{
 
   font-size:1.2rem;
  }
}

.an-holder{
  display:flex;
  flex-direction: row;
  width:100%;
  margin-top:14em;
  position:relative;
}
.title-holder{
 
  &::after{
content: "";
width:0;
height:0;
border-style:solid;
border-width:130px 60px 0 0;
border-color:#f3dba1 transparent transparent transparent;
position:absolute;
top:0;
right:0;
transform:translateX(100%)
    }
}
.scene-center{
  display:flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  height:auto;

 padding-bottom:0em;
  
  hr{
    display: block;
     height: 15px;
     width:80rem;
    border: 0;
     border: 1px solid #3269d0;
     background-color:#32a0d0;
    margin: 3em 1em;
     padding: 0;
     border-radius:100%;
  }
}
.containetaj {
	padding-top: 0rem;
}
.etaje {
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.informatii{
  display:flex;
  position: relative;
width:40%;
p{
  width:100%;
}
}


.flerat {
	display: flex;
	
  align-items: center;
  justify-content: center;
	flex-direction: column;


	background-color: rgba(236, 236, 236, 0.938);
	box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.2);
	button {
	background-color: #2776b0;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	box-shadow: 10px 10px 13px rgba(0,0,0,0.20);
	margin-top: 1rem;
	margin-left:10rem;
	font-size: 1.2rem;
	padding: 1rem 2rem;
	&:hover {
		color: #fdf9f9;
		background-color: rgb(3, 134, 171);
		transition: .3s;
	}
}
}


.dual{
  display:flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width:100%;
  height:auto;
  padding:0em;
  hr{
    height:40rem;
  width:15px;
  margin: 1em 5em;
  }
}
.info-etaj {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	padding-left: 2rem;
	padding-right: 2rem;
	h1 {
		font-size: 1.7rem;
		color: rgb(80, 79, 79);
		text-transform: uppercase;
		border-bottom: 1px dotted rgb(80, 79, 79);
		
		margin-bottom: 2rem;
		color: #313131;
	}
  h2{
    font-size: 1.3rem;
  }
	h4 {
		font-size: 2rem;
		color: rgb(80, 79, 79);
	}

	p {
		font-size: 1.3rem;
		color: rgb(80, 79, 79);
	}
}
@media only screen and (max-width: 995px) {
  .dual{
    flex-direction: column-reverse ;
    hr{
      height: 15px;
     width:80rem;
     margin: 3em 5em;
    }
  }
  .scene-center{
    padding:1.5em;
    margin-top:5em;
  }
  .map-container{width:100%}
  .informatii{width:100%}
  .no-mic{
    display:none;
  }
.padder{
  
  padding:2.3rem;
}
.bounce {
  
  -moz-animation: bounce 0s infinite;
  -webkit-animation: bounce 0s infinite;
  animation: bounce 0s infinite;
}
.arrow {
  font-size:0.9rem;
  
  
}
}