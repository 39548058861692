
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Grey+Qo&display=swap');
$color: #32a0d0;

#team {

  inset: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-top:6rem;
  justify-content: center;
  background-color:#e6decbde;


p{
color:rgba(0, 0, 0, 0.898);

letter-spacing: 0.05rem;

}
span{

  letter-spacing: 0.05rem;
}
}

 .gal-cont{
      text-align: center;
      
    h1{
      font-size: 1.5rem;
    }
  }
.bold {
  font-weight: bold;
}
.section-wrapper {
  position: relative;
}
.section-parallax {
  display: flex;
  position: relative;
  padding: 7em;
  flex-direction: column;
}

.section-parallax > div {
  margin: 60px;
}
.section-parallax h3.entry-title {
  font-size: 16px;
  line-height: 149%;
  font-weight: 400;
  word-break: break-word;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  margin-top: 32px;
}

.no-padding .section-wrapper {
  padding: 0 !important;
  width: 100%;
 
}

.member {
  display: flex;
  align-items: center;
  justify-content: center;
  

  img {
    display: inline-block;
    vertical-align: middle;
    max-width: 00px;
  }
}

.member-content {
  margin-bottom: 35px;
}

.member-right .member-info {
  margin-left: auto;
  margin-right: -130px;
}

.member-postition {
  margin: 0;
  font-size: 16px;
  letter-spacing: 2px;
}

.member-name {
  font-size: 48px;
  font-weight: 700;
  color: #000;
  line-height: 150%;
  margin-bottom: 35px;
  margin-top: -5px;
  padding: 0;
}

.member-social-holder {
  display: flex;
  flex-direction: row;
  background-color:rgba(7, 100, 167, 0.858);
  border-radius:1rem;
  padding: 15px 10px !important;
  width: fit-content !important;
}

.member-right .member-social-holder {
  left: auto;
  right: 0px;
}

.member-social-holder .social a {
  color: #fff !important;
  padding: 30px;
}
.member-social-holder2 .social a {
  color: #fff !important;
  padding: 30px;
}

.member-social-holder {
  padding: 10px;
}
.social {
  display: inline-block;
  font-size: 25px;
}

.member-left {
  display: flex;
  flex-direction: row;
  height: 33%;
  
  

}

.member-right {
  display: flex;
  flex-direction: row;
  height: 33%;
}
.member-left2 {
  display: flex;
  flex-direction: row;
  height: 33%;
 
  img{
    border-radius: 10px;
  }
}
.member-info {

  display: flex;
  flex-direction: column;
    width: 50%;
  background-color: #e6decb;
  padding: 65px 110px;
  vertical-align: middle;
  right: 0px;
  text-align: left;
  border-radius:0.5rem;
  position: relative;
  z-index: 31;
  max-width: 400px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.member-info2 {
  width: 50%;
  background-color: #e6decb;
  padding: 65px 110px;
  display: inline-block;
  vertical-align: middle;
  border-radius:0.5rem;
  left: 0px;
  text-align: left;
  position: relative;
  z-index: 31;
  max-width: 400px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
}

.member-info3 {
  width: 50%;
  background-color: #e6decb;
  max-width: 400px;
  padding: 65px 110px;
  border-radius:0.5rem;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  position: relative;
  z-index: 31;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
}

.ima1 {
  width: 50%;
  max-width: 500px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  img {
    height: 80vh;
    border-radius:0.5rem ;  
  }
}
.ima2 {
  width: 50%;
  max-width: 500px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  img {
    height: 80vh;
    border-radius:0.5rem ;
  }
}
.ima3 {
  width: 50%;
  max-width: 500px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  
  img {
    height: 80vh;
    border-radius:0.5rem ;
  }
}

.galerie {
display: inline-block;
  padding: 1rem 2rem;
  border-radius: 10rem;
  color: black;
  text-transform: uppercase;
  font-size: 1rem;
  
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 300;
  cursor: zoom-in;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color;
    
    z-index: -2;
  }
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: darken($color, 15%);
    transition: all .3s;
    
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
  
}

  .ReactModal__Overlay{
    z-index: 10000 !important;
  }
 
.co{
  display:flex;
  float: inline-end;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.to-member{
  display:flex;
  flex-direction: column;
  align-items:center;
}


@media only screen and (max-width: 1200px) {
 .member-content{
   display: flex;
   align-items: center !important;
 }
  .section-parallax {
    padding: 0px;
  }

  .social {
    font-size: 1em;
  }
  .member {
    flex-direction: column;
    
    
  }
  .galerie{
    margin:0;
    margin-top:0px;
    
    border-radius: 0%;
    
  }
 
  .member-info {
    padding: 5px !important;

    width: 100% !important;
    max-width: none;
     h5 {
      padding-right: 0em !important;
      padding-left: 0.5em;
      max-width: fit-content !important;
    }
  }
  .member-info2 {
    padding: 5px !important;
    width: 100% !important;
    max-width: none;
    h5 {
      padding-right: 0em !important;
      padding-left: 0.5em;
      max-width: fit-content !important;
    }
  }
  .member-info3 {
    padding: 5px !important;
    width: 100%;
    max-width: none;
    h5 {
      padding-right: 0em !important;
      padding-left: 0.5em;
      max-width: fit-content !important;
    }
  }
  .member-left {
    flex-direction: column !important;
    img {
      transform: none !important;
    }
  }
  .member-left2 {
    flex-direction: column !important;
    img {
      transform: none !important;
    }
  }
  .member-right {
    
    flex-direction: column-reverse !important;
    img {
      transform: none !important;
    }
  }
  .member-content {
    inset: 0rem;
    display: flex;
    flex-direction: column;
    padding: 20px;
    p {
      padding-right: 0.5em;
      padding-left: 0.5em;
    }
  }
  .member-social-holder {
    display:flex;
    margin-left:-10px;
    left:0;
    right:0;
   
    width: 100% !important;
    justify-content: center;
    align-items: center;

  }
 
  .galerie{
    
    margin-top:10px;
    left:0;
    right:0;
   border-radius:20px;
    width: 100% !important;
    justify-content: center;
  }
  .ima1 {
    width: 100% !important;
    height: 20em;
    max-width: none;
    img {
      height: 100%;
      width: 90%;
    }
  }
  .ima2 {
    width: 100% !important;
    height: 20em;
    max-width: none;
    img {
      height: 100%;
      width: 80%;
    }
  }
  .ima3 {
    width: 100% !important;
    height: 20em;
    max-width: none;
    img {
      height: 100%;
      width: 80%;
    }
  }
}


@media only screen and (max-width: 768px) {
  .section-parallax > div {
  margin:20px 10px;
}
  #team{
    align-items:center;
    justify-content: center;
    
    img{
      max-height:20em;
      margin:0px
    }
  }
  .gal-cont{
      text-align: center;
   
    
    h1{
      font-size: 1.6rem;
    }
  }
  .to-member{
    padding:1em;
    margin-left:-10px !important;
    align-items:center;
    display:flex;
    flex-direction: column;
    position:relative;
    
  }
  .member-social-holder{

    display:flex;
  }

 

}

@media only screen and (max-width: 425px) {
  .section-parallax > div {
  margin:20px 10px;
}
  #team{
    align-items:center;
    justify-content: center;
    
    img{
      max-height:20em;
      margin:0px
    }
  }
  .gal-cont{
    height: 100%;
     
      
    h1{
      height: 3rem;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1.4rem;
    }
  }
  
  

}