

#container {
 
  h1 {

  text-align: center;
 
}
}

.parent {
  width: 100%;
}
.map-container{
  display:flex;
  position:relative;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin:0;
  
  top:0;
  left:0;
  right:0;
  bottom:0;
  padding:0;
   a { color: rgba(0, 0, 0, 0.755); text-decoration: none; }
   h1{
     margin-top:4em;
     padding:2em;
     text-align: center;
     font-size:1.5rem
   }
}

@media only screen and (max-width: 1200px) {
  .map-container {
  margin-top:5em;
h1{
  margin-top:0em;
  font-size:1.2rem
}

}
}


