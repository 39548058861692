.about-container {
  display: flex;
  align-items: row;
  height: 100%;
figure{
  background-color:#e6decb;
  z-index:2;
}
}

.half-right {
  width: 100%;
  padding: 0px;
  

  img {
    height: 50rem;
    width: 100%;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
}
.half-left {
  background-color:#e6decb;
  width: 100% !important;
  float: none;
  margin-right: auto !important;
  margin-left: auto !important;
  text-align: left;
  padding: 10rem;
  img{
    float: center;
    background-color: #e1d1ae;
    height:8rem;
  }
  h2 {
    text-align: left;
  }
}
.img-holder{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
}
@media only screen and (max-width: 700px) {
  .half-left {
    padding: 0px;
  }

  .about-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    height: 100%;
    a{
      display: none;
    }
  }
  .half-right {
    img {
      display: none;
    }
  }
}
.img-holder {
  img{
    height:8rem;
  }
}
.half-left{
  p{
    padding:1rem;
  }
}
.title-description-up {
  font-size: 16px;
  margin-bottom: 0;
  letter-spacing: 2px;
}
.entry-title {
  font-size: 2rem;
  line-height: 149%;
  font-weight: 400;
  word-break: break-word;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  margin-top: 32px;
}

.medium-text {
  color: black;
  font-size: 60px;
  line-height: 120%;
  font-weight: 700;
  padding-bottom: 35px;
}
