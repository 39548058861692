.container-parteneri{
    display: grid;
    
    grid-auto-flow: column;
   position: relative;
   background-color: #e6deca;
   
  
    img{
        margin:1em;
        width:20rem;
        height: auto;
       align-self: center;
  justify-self: center;
    }
}


@media only screen and (max-width: 1300px) {
  .container-parteneri{  
      margin-top: 10em;
    grid-auto-flow: row;
   
   
  
}
}