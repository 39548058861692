@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;700;900&display=swap');
.container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	padding-top: 4rem;
	padding-bottom: 6rem;
	margin-bottom: 20rem;
	margin-top:5em;
	
}
.Biggest-container{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	
}
.up-container{
	display: flex;
	justify-content: center;
	flex-direction: row;
	margin-top: 10rem;
	margin-bottom: 5rem; 
	padding-top: 10rem;
	padding-bottom: 2rem;
}
.title-parteneri {
	font-size: 2.8rem;
	padding-bottom: 2rem;
	letter-spacing: 1.5px;
	color: rgba(31, 30, 30, 0.938);
	padding-bottom: 3rem;
	text-align: center;
	text-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}
.text-content {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 750px;
	text-align: left;
	font-size: 2rem;
}
.text-content-2 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 750px;
	text-align: left;
	font-size: 1.4rem;

}
.left-top-container{
	margin-right:5rem;
	padding-top: 5rem;
}
.big-box {
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding-left: 5rem;
	padding-right: 5rem;
	margin-left: 5rem;
	margin-right: 5rem;
}
.big-box2 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem;
	margin-bottom: 3rem;
	padding: 2rem 5rem;
	margin-left: 5rem;
	margin-right: 5rem;
}
.style-boxing-1 {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 40%;
	img {
		width: 200px;
		height: 200px;
		border-radius: 50%;
	}
}
.style-boxing-2 {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;
	width: 60%;
	margin-right: 1rem;
	.header-text {
	font-size: 1.5rem;
	letter-spacing: 1.2px;
	color: #1b1b1b;
	padding: 1.2rem 1rem 0.5rem 1rem;
	text-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
	.text {
	color: rgb(122, 118, 118);
	display: flex;
	font-weight: bold;
	align-items: flex-start;
	text-align: left;
	padding: .5rem 2rem 2rem 2rem;
	border-radius: 15px;
}
}
}
.box-1 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;
	height: 100%;
	margin-right: 2rem;
	border-radius: 10px;
	background-color: rgb(247, 247, 247);
	box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.25);

}
.box-2 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;
	margin-right: 2rem;
	border-radius: 10px;
	background-color: rgb(247, 247, 247);
	box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.25);
	
}
.box-3 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;
	margin-right: 2rem;
	border-radius: 10px;
	background-color: rgb(247, 247, 247);
	box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.25);

}
.box-4 {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 50%;
	height: 100%;
	margin-right: 2rem;
	border-radius: 10px;
	background-color: rgb(247, 247, 247);
	box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.25);

}


.btn-1{
	padding: 1rem 1.5rem;
	border-radius: 15px;
	margin-bottom: 1rem;
	color: #fff;
	cursor: pointer;
	a{
		color: rgb(0, 0, 0);
	}
	&:hover {
	border-radius: 10px;
	background-color: rgb(247, 247, 247);
	box-shadow: 10px 14px 10px rgba(0, 0, 0, 0.25);
	animation: linear infinite;
	transition: 400ms;
	a{
		color: black;
	}
	}

}

@media only screen and (max-width: 1400px) {
	.container {
		height: 100%;
		padding-bottom: 2rem;
		margin-top: 0;
	}
	.up-container{
		display: flex;
		flex-direction: column;
	}
	.big-box, .big-box2{
		width: 100%;
	}
	.style-boxing-1 {
		img {
			margin-left: 2rem;
		}
	}
}

@media only screen and (max-width: 1300px) {
	.container {
		height: 100%;
		margin-bottom: 0;
		margin-top: 0;
		padding-top: 0;
	}
	.up-container{
		display: flex;
		flex-direction: column;
		margin-top: 0;
	}
	.style-boxing-1{
		margin-left: 1rem;
		margin-right: 1rem;
	}
	.text-content-2{
      width: 90%;
	  font-size:1rem;
	  text-align: center;
	}
	.big-box{
		width: 100%;
	}
	.style-boxing-1 {
		img {
			margin-left: 2rem;
		}
	}
}
@media only screen and (max-width: 1100px) {
	.container {
		height: 100%;
		display: flex;
		flex-direction: column;
		padding-bottom: 2rem;
	}
	.Biggest-container{
		width: 98%;
	}
	.up-container{
		margin-top: 1rem;
		padding-top:1rem;
		padding-bottom: 2rem;
	}
	.left-top-container{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction:column;
		margin-right:0;
		
	}
	.right-contact{
		display: none;
	}
	.text-content-2{
		width: 80%;
	}
	.title-parteneri {
		text-align: center;
	    padding-bottom: 1rem;
		font-size: 2.5rem;
	}
	
	.style-boxing-1 img{
		margin-left: 0;
		padding-top: 0;
		height: 200px;
	}
	.style-boxing-2 {
		width: 40vw;
		height: 80%;
	}
	.btn-1{
        padding: 0.5rem 1rem;
		font-size: 1rem;
	}

	.box-1,.box-3 {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-size: 2rem;
	   padding-top: 0.7rem;
	   margin-left: 2rem;
	}
	.box-2,.box-4{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-size: 2rem;
	   padding-top: 0.7rem;
	   margin-right: 2rem;
	}
	.text {
		font-size: 17px;
		padding-left: 1rem;
	}
}
@media only screen and (max-width: 900px) {
	.container {
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;
		padding-top: 2rem;
	}
	.left-top-container{
	display: flex;
	width:  100%;
	flex-direction: column;
	padding-top: 0;
	}
	.text-content-2{
      width: 90%;
	  font-size:1rem;
	  text-align: center;
	}
	.text-content{
		width: 100%;
		display: flex;
		justify-content: center;
	    text-align:center;
		padding-top: 2rem;
	}
	.up-container{
		margin-top: 1rem;
		padding-top:2rem;
		padding-bottom:0;
	}

	.btn-1 a{
		font-size: 15px;

	}
	.style-boxing-1 img{
		margin-left: 0;
		padding-top: 0;
		height: 200px;
	}
	
	.title-parteneri {
		text-align: center;
	    padding-bottom: 1rem;
		font-size: 2.5rem;
	}
	.btn-1{
        padding: 0.5rem 1rem;
	}
	.big-box {
		display: flex;
		flex-direction: column;
	}
	.big-box2 {
		display: flex;
		flex-direction: column;
	}
	.box-2 {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		font-size: 2rem;
		width: 90vw;
		padding-bottom: 3rem;
		padding-top: 1.5rem;
		margin-right: 0;
	}
	.box-4 {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		font-size: 2rem;
		width: 90vw;
		padding-bottom: 3rem;
		padding-top: 1.5rem;
		margin-right: 0;
	}
	.box-1 {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		font-size: 2rem;
		width: 90vw;
		padding-bottom: 3rem;
		margin-bottom: 2rem;
		padding-top: 1.5rem;
		
	}
	.box-3 {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		font-size: 2rem;
		width: 90vw;
		padding-bottom: 3rem;
		margin-bottom: 2rem;
		padding-top: 1.5rem;
		
	}
	.style-boxing-2 {
		width: 90vw;
		height: 80%;
		margin-right: 0;
	}
	.text {
		padding-left: 3rem;
		padding-right: 3rem;
	}
	.text-content {
		font-size: 1.4rem;
		width: 85%;
	}
}
@media only screen and (max-width: 768px) {
	.container {
		margin-left: .05rem;
	}
	.up-container{
		margin-top: 2rem;
		padding-top:2rem;
	}
	.text-content{
		display: flex;
		justify-content: center;
		text-align: center;
		margin: 0;	
	}
	.text-content-2{
      width: 90%;
	  font-size:1rem;
	  text-align: center;
	}
	.btn-1{
        padding: 0.5rem 1rem;
	}
	.title-parteneri {
		padding-bottom: 1rem;
		text-align: center;
	}
	.left-top-container{
		display: flex;
		align-items: center;
		justify-content: center;

	}
	.right-contact{
		display: none;
	}
	.big-box {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
	}

	.style-boxing-1 img{
       margin-left:0;
	   width: 200px;
	   height: 200px;
	}
	.big-box2 {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-bottom: 0.2rem;
	}
	.btn-1 a{
		font-size:15px;
	}
	
	.text {
		padding-right: 1rem;
		font-size: 18px;
	}
}
@media only screen and (max-width: 728px) {
	.box-1,.box-2,.box-3,.box-4 {
	&:hover{
		transition:none;
	}
}
	.container{
	  margin-left: 0;
	}
	.up-container{
		margin-top: 2rem;
		padding-top:2rem;
		padding-bottom: 0;
	}
	.text-content {
		display: flex;
		justify-content: center;
		text-align: center;
		padding-right: 2rem;
		padding-left: 2rem;
		margin-top: 2rem;
		margin-bottom: 0;
		font-size: 1.8rem;
	}
	.left-top-container{
		display: flex;
		align-items: center;
		justify-content: center;

	}
	.text-content-2{
      width: 90%;
	  font-size:1rem;
	  text-align: center;
	}
	.text{
		font-size: 15px;
		text-align: left;
	}
	.header-text{
		font-size: 15px;
	    text-align: center;
		text-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
	}
	.style-boxing-1 img{
		width: 250px;
		height: 100px;
		border-radius: 30%;
		margin-left: 0;
		box-shadow: 10px 14px 10px rgba(0, 0, 0, 0.25);
	}
	
	.title-parteneri {
		padding-bottom: 1rem;
		text-align: center;
		font-size: 1.9rem;
		text-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
	}
}

@media only screen and (max-width: 320px) {
	.container{
	  margin-left: 0;
	}
	.up-container{
		margin-top: 2rem;
		padding-top:2rem;
	}
	.text-content {
		
		margin-bottom: 0;
		font-size: 1.8rem;
		width: 100vw;
	}

	.left-top-container{
		display: flex;
		align-items: center;
		justify-content: center;

	}
	.text-content-2{
		width: 65%;
		text-align:left;	
	}
	
	.text{
		font-size: 15px;
		text-align: left;
	}
	.header-text h1{
		font-size: 15px;
		padding-left: 0;
	    text-align: center;
	}
	.style-boxing-1 img{
		width: 150px;
		height: 100px;
		border-radius: 10px;
		margin-left: 0;
		box-shadow: 10px 14px 10px rgba(0, 0, 0, 0.25);
	}
	.style-boxing-1{
		border-radius: 10px;
	}
	.box-1{
		margin-right: 2rem;
	}
	.box-3{
		margin-right: 2rem;
	}
	.box-2{
		margin-right: 0;
	}
	
	.box-4{
		margin-right: 0;
	}
	.title-parteneri {
		padding-bottom: 1rem;
		text-align: center;
	}
}
